<template>
  <div class="home">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      loader="spinner"
      :width="130"
      :height="130"
      color="#0F5FFE"
    ></loading>

    <!-- carbuy martelinho -->
    <!-- <v-app-bar
      v-if="dadosEmpresa.name === 'CARBUY'"
      tile
      class="brade-color-primary"
      height="45%"
    >
      <div class="d-flex align-center">
        <v-img
          style="cursor: pointer"
          title="Atualizar página"
          @click.native="$router.go(0)"
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="logoCarbuy"
          transition="scale-transition"
          width="201"
        />
      </div>

      <v-spacer></v-spacer>

      <a
        class="white--text ma-md-2"
        :href="dadosEmpresa.restoConfig.LinkAjuda"
        style="text-decoration: none"
        >Ajuda</a
      >
    </v-app-bar> -->

    <!-- <v-app-bar
      v-if="dadosEmpresa.name === 'CARBUY'"
      tile
      class="brade-color-primary"
      height="45%"
    >
      <div class="d-flex align-center">
        <v-img
          style="cursor: pointer"
          title="Atualizar página"
          @click.native="$router.go(0)"
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="logoCarbuy"
          transition="scale-transition"
          width="201"
        />
      </div>

      <v-spacer></v-spacer>

      <a
        class="white--text ma-md-2"
        :href="dadosEmpresa.restoConfig.LinkAjuda"
        style="text-decoration: none"
        >Ajuda</a
      >
    </v-app-bar> -->

    <div>
      <v-navigation-drawer
        v-model="drawer"
        v-if="$vuetify.breakpoint.xsOnly"
        absolute
        temporary
      >
        <v-list dense>
          <v-list-item
            v-for="(menu, index) in this.listMenuCarBuy"
            :key="index"
            link
            :href="menu.link"
          >
            <v-list-item-icon>
              <v-icon color="red">{{ menu.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ menu.nome }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list-item>
          <!-- <v-list-item-avatar>
            <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
          </v-list-item-avatar> -->

          <v-list-item-content>
            <v-list-item-title @click="sairEletronico"
              ><v-icon color="red">mdi-account</v-icon
              >{{ usuario }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-navigation-drawer>

      <v-app-bar tile class="brade-color-primary" height="14"> </v-app-bar>

      <v-container>
        <v-app-bar class="brade-header2" elevation="0" tile color="#e1e1e1">
          <div class="d-flex align-center">
            <v-img
              style="cursor: pointer"
              title="Página inical"
              @click.native="clicarImage(dadosEmpresa.restoConfig.Home)"
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              :src="logoCarbuy"
              transition="scale-transition"
              width="201"
            />
          </div>

          <v-spacer></v-spacer>

          <div class="brade-nav-link">
            <a
              v-for="(menu, index) in listMenuCarBuy"
              :key="index"
              :class="menu.ClasseNome"
              :href="menu.link"
              :style="menu.estiloNome"
              >{{ menu.nome }}</a
            >
            <!-- <a
              class="black--text ma-md-3"
              :href="dadosEmpresa.restoConfig.Menu1"
              style="text-decoration: none"
              >Destaques</a
            >

            <a
              class="black--text ma-md-3"
              :href="dadosEmpresa.restoConfig.Menu2"
              style="text-decoration: none; opacity: 0.7"
              >Comprar</a
            >

            <a
              class="black--text ma-md-3"
              :href="dadosEmpresa.restoConfig.Menu6"
              style="text-decoration: none; opacity: 0.7"
              >Frete</a
            >

            <a
              :href="dadosEmpresa.restoConfig.Menu5"
              style="text-decoration: none; opacity: 0.7"
              class="black--text ma-md-3"
              >Formas de Pagamento</a
            >

            <a
              class="black--text ma-md-3"
              :href="dadosEmpresa.restoConfig.Menu3"
              style="text-decoration: none; opacity: 0.7"
              >Perguntas Frequentes</a
            >

            <a
              :href="dadosEmpresa.restoConfig.LinkAjuda"
              style="text-decoration: none; opacity: 0.7"
              class="black--text ma-md-3"
              >Fale Conosco</a
            > -->

            <div style="float: right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-md-3"
                    v-bind="attrs"
                    v-on="on"
                    title="Sair"
                    dark
                    small
                    height="34"
                    elevation="0"
                    width="72"
                    color="#565656"
                    @click.native="sairEletronico"
                  >
                    Sair
                    <!-- <v-icon>mdi-book-arrow-left-outline</v-icon> -->
                  </v-btn>
                </template>
                <span>{{ usuario }}</span>
              </v-tooltip>
            </div>
          </div>
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.xsOnly"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </v-app-bar>
      </v-container>
    </div>

    <!-- <v-app-bar  class="brade-color-primary">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="dadosEmpresa.icon"
          transition="scale-transition"
          width="40"
        />
        <h3 class="white--text">Eletrônico</h3>
        <v-col>
          <v-btn
            title="Atualizar"
            class="brade-color-primary"
            fab
            small
            dark
            @click.native="$router.go(0)"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <h5 class="white--text">{{ dadosEmpresa.LeilaoData }}</h5>

      </div>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            title="Sair"
            class="brade-color-primary"
            fab
            small
            dark
            @click.native="sairEletronico"
          >
            <v-icon>mdi-book-arrow-left-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ usuario }}</span>
      </v-tooltip>

      <v-btn class="white--text" :href="dadosEmpresa.link" text>
        <span class="mr-2 white--text">{{ dadosEmpresa.name }}</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar> -->
  </div>
</template>

<script>
import LogoCarbuy from "../../assets/img/carbuy.png";

// import config from "../../assets/json/config.json";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { mapActions, mapState } from "vuex";

export default {
  name: "Nav",
  props: {
    header: {
      type: Object,
    },
  },
  data() {
    return {
      drawer: null,
      logoCarbuy: LogoCarbuy,
      dadosEmpresa: {},
      loading: false,
      leilao: "",
      hash: "",
      usuario: "",
      corHeaderPorEmpresa: "primary",
      listMenuCarBuy: [],
    };
  },
  components: {
    Loading,
  },
  created() {
    this.verificaLogin();
  },
  mounted() {
    this.carregaHeader();
  },
  methods: {
    ...mapActions(["setEncerraLogin"]),
    carregaHeader() {
      this.leilao = this.$route.params.name;
      this.hash = this.$route.params.hashValmiki;
      if (!this.leilao) {
        // alert("leilao não passado, caso o erro persista, entre contato com TI");
        this.$toastr.i(
          "LEILAO NÃO PASSADO, CASO O ERRO PERSISTA, ENTRE CONTATO COM TI"
        );

        console.error("o paramentro leilao não existe no header");
        this.loading = false;
      }
      // this.dadosEmpresa = this.header;

      if (this.header) {
        this.dadosEmpresa = this.header;
      } else {
        let urlBaseConfig = `https://central.carbuy.com.br/frm3Realizacao/Json/Config.aspx?LeilaoCod=${this.leilao}&Hash=${this.hash}&PessoaCod=${this.$route.params.codCliente}&PessoaHash=${this.$route.params.hashPessoa}`;
        this.loading = true;
        this.$axios
          .get(`${urlBaseConfig}`)
          .then((response) => {
            const config = response.data;
            let { Empresa, EmpresaLink, LeilaoData, ...restoConfig } =
              config.ConfigLeilao;
            let logo;
            switch (Empresa?.toLowerCase()) {
              case "vipleiloes":
                logo = Empresa?.toLowerCase();
                break;
              case "leilaovip":
                logo = Empresa?.toLowerCase();
                break;
              case "hastavip":
                logo = Empresa?.toLowerCase();
                this.corHeaderPorEmpresa = "warning";
                break;
              case "carbuy":
                logo = Empresa?.toLowerCase();
                this.corHeaderPorEmpresa = "brade-color-primary";
                break;
              default:
                logo = "visitante";
                break;
            }
            this.dadosEmpresa = {
              name: Empresa,
              link: EmpresaLink,
              logoEmpresa: logo,
              icon: require(`../../assets/img/${logo}.png`),
              LeilaoData,
              corHeaderPorEmpresa: this.corHeaderPorEmpresa,
              restoConfig,
            };
            // console.log(this.dadosEmpresa.restoConfig);

            this.carregaMenuCarbuy();
          })
          .catch((e) => {
            console.error(
              "Excecao ao buscar dados da api para setar informações no header ",
              e.message
            );
            // ALERT(
            //   "OCORREU UM ERRO AO BUSCAR AO DADOS, CASO PERSISTA ENTRE COM CONTATO COM VIP TI SLZ, ACESSAR CHROME DEV TOOLS PARA MAIS INFORMAÇÕES"
            // );
            this.$toastr.e("Erro no servidor");
          })
          .finally(() => (this.loading = false));
      }
    },
    sairEletronico() {
      this.limparTodosTimesDobrowser();

      sessionStorage.removeItem("dados_login");

      if (this.dadosEmpresa.restoConfig.Login) {
        window.location.href = this.dadosEmpresa.restoConfig.Login;
      } else {
        this.$router.push({
          path: `/login/${this.leilao}`,
        });
      }

      this.setEncerraLogin();
    },
    limparTodosTimesDobrowser() {
      let id = window.setTimeout(() => {}, 0);
      console.log(id);
      while (id) {
        window.clearTimeout(id);
        id--;
      }

      id = window.setInterval(() => {}, 0);
      console.log(id);
      while (id) {
        window.clearInterval(id);
        id--;
      }
    },
    verificaLogin() {
      this.usuario = this.$store.loginData
        ? this.$store.loginData.Login.Apelido
        : this.$route.params.apelido;
    },
    clicarImage(url) {
      if (url) {
        window.location.href = url;
      } else {
        this.$router.go(0);
      }
    },
    carregaMenuCarbuy() {
      this.listMenuCarBuy = [
        {
          nome: "Regulamento",
          ClasseNome: "black--text ma-md-3",
          estiloNome: "text-decoration: none; opacity: 0.7",
          link: this.dadosEmpresa.restoConfig.Menu4,
          icon: "mdi-file-document-multiple-outline",
        },
        {
          nome: "Destaques",
          ClasseNome: "black--text ma-md-3",
          estiloNome: "text-decoration: none; opacity: 0.7",
          link: this.dadosEmpresa.restoConfig.Menu1,
          icon: "mdi-car-back",
        },
        {
          nome: "Comprar",
          ClasseNome: "black--text ma-md-3",
          estiloNome: "text-decoration: none",
          link: this.dadosEmpresa.restoConfig.Menu2,
          icon: "mdi-cart",
        },
        {
          nome: "Frete",
          ClasseNome: "black--text ma-md-3",
          estiloNome: "text-decoration: none; opacity: 0.7",
          link: this.dadosEmpresa.restoConfig.Menu6,
          icon: "mdi-truck",
        },
        {
          nome: "Formas de Pagamento",
          ClasseNome: "black--text ma-md-3",
          estiloNome: "text-decoration: none; opacity: 0.7",
          link: this.dadosEmpresa.restoConfig.Menu5,
          icon: "mdi-credit-card",
        },
        {
          nome: "Perguntas Frequentes",
          ClasseNome: "black--text ma-md-3",
          estiloNome: "text-decoration: none; opacity: 0.7",
          link: this.dadosEmpresa.restoConfig.Menu3,
          icon: "mdi-progress-question",
        },
        {
          nome: "Fale Conosco",
          ClasseNome: "black--text ma-md-3",
          estiloNome: "text-decoration: none; opacity: 0.7",
          link: this.dadosEmpresa.restoConfig.LinkAjuda,
          icon: "mdi-comment-text-multiple",
        },
      ];
    },
  },
};
</script>

<style lang="css">
.brade-header2 .v-toolbar__content {
  padding: 0px !important;
}
</style>