<template>
  <v-footer
    style="background-color: #020202 !important"
    dark
    :absolute="screen === 'eletronico'"
    :padless="screen === 'martelinho'"
  >
    <v-col class="text-left" cols="12">
      <p style="font-size: 11px">
        {{ texto }}
      </p>
    </v-col>

    <div class="fixed-buttons">
      <a
        href="https://api.whatsapp.com/send?1=pt_BR&amp;phone=+551137770214"
        target="_blank"
        class="whatsapp-link"
      >
        <v-icon large color="white">mdi-whatsapp</v-icon>
      </a>

      <a
        href="https://api.whatsapp.com/send?1=pt_BR&amp;phone=+551137770214"
        target="_blank"
        class="btn-custom btn-blue cotar-frete-link"
        >Cotar Frete</a
      >
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    texto: {
      type: String,
      default: "",
    },
    screen: {
      type: String,
      default: "eletronico",
    },
  },
};
</script>

<style  scoped>
</style>